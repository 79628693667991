// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://trackapi-st2.bpost.cloud',
  // apiUrl: 'https://j8yq3a1lm3.execute-api.eu-west-1.amazonaws.com',
  // apiUrl: "http://localhost:3010",
  // apiUrl: "//trackapi-dv2.bpost.cloud",
  // apiUrl: "//trackapi-ac2.bpost.cloud",
  // apiUrl: "//trackapi.bpost.cloud",
  // apiUrl: "//trackapi.bpost.be",
  // faqBaseURL: "//www-2.stbpost.be/",
  footerBaseURL: '//nbw-ac2.bpost.cloud/',
  faqURLSubString: '/site/applications/track/',
  chatBotURL: '/itt-chatbot', // Do not remove https protocol
  drupalBaseURL: '//nbw-st2.bpost.cloud/',
  googleMapsLink: '//www.google.com/maps/place/',
  // captchaSiteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI", // Test Captcha
  captchaSiteKey: '6LdGIMMUAAAAAP4elHqofHP16OqgE__mZTaSfJFB', // *.bpost.cloud Captcha
  // captchaSiteKey: "6Ldq6QsTAAAAAPHEV8on2Sj1g41jtZAAWR7jie_-", // *.bpost.be Captcha
  // deliveryPrefURL: "https://track.bpost.be/preferences/?language=",
  usabillaKeys: [{ en: '5b8f96e44667904c5b7d30ad' }, { fr: '5b8e59e346679013030fdfee' }, { nl: '5b8e5cb3466790241e571205' }],
  faqId: '1851',
  googlePlayStorePackageCode: 'com.bpost.packami_droid',
  // bpostURL: "//www.bpost.be/",
  barCodeImage: 'sites/default/files/complete_html/P_NL.jpg',
  trackBpostURL: '//das-st1.bpost.cloud/',
  lmgBaseUrl: '//landmarkglobal.com/',
  gtmId: 'GTM-WJ4RB5',
  apiCryptoKey: '/404b1967-6507-45ab-8a6d-7374a3f478be/',
  itemonroundstatus_time: 10000
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
